.embla {
	--slide-spacing: 1rem;
	--slide-size: 100%;
	--slide-height: 35rem;
	@apply h-full relative;
	/* padding: 1.6rem; */
}

.embla__viewport {
	@apply h-full overflow-hidden;
}

.embla__container {
	@apply max-h-full;
	backface-visibility: hidden;
	display: flex;
	touch-action: pan-y;
	flex-direction: row;
	height: auto;
	margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
	flex: 0 0 var(--slide-size);
	min-width: 0;
	padding-left: var(--slide-spacing);
	position: relative;
}

.latest-cars {
	@apply flex-[0_0_100%] md:flex-[0_0_50%] lg:flex-[1_0_33%];
}
.car-info {
	@apply h-[350px] md:h-[600px];
}

.embla__slide__img {
	display: block;
	height: var(--slide-height);
	width: 100%;
	object-fit: cover;
}

.embla__dot,
.embla__button {
	-webkit-appearance: none;
	background-color: transparent;
	touch-action: manipulation;
	display: inline-flex;
	text-decoration: none;
	cursor: pointer;
	border: 0;
	padding: 0;
	margin: 0;
}

.embla__dots {
	z-index: 1;
	bottom: 0rem;
	position: absolute;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.embla__dot {
	width: 0.275rem;
	height: 0.275rem;
	display: flex;
	align-items: center;
	margin: 0.5rem 0.125rem;
}

.embla__dot:after {
	@apply bg-black;
	border-radius: 0.5rem;
	width: 100%;
	height: 100%;
	content: "";
}

.embla__dot--selected:after {
	@apply bg-accent bg-opacity-50;
}

.embla__button {
	@apply text-accent;
	z-index: 1;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	width: 3rem;
	height: 3rem;
}

.embla__button--prev {
	left: 0.0125rem;
}

.embla__button--next {
	right: 0.0125rem;
}

.embla__button:disabled {
	opacity: 0.3;
}

.embla__button__svg {
	width: 65%;
	height: 65%;
}
